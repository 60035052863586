.nav {
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background-color: #b87333;
  color: #fff;
  align-items: center;
  z-index: 9;
}

.nav h1 {
  flex: 1;
  padding: 0 20px;
  margin: 0;
  font-size: 1rem;
}

.nav .addPanel {
  flex: 3;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

ul.addPanel {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

ul.addPanel li {
  color: #fff;
  background: #b87333;
  display: block;
  margin: 0;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
}

ul.addPanel > li {
  min-width: 150px;
  padding: 15px;
  border-right: 1px solid #a56122;
}

ul.addPanel li:hover,
ul.addPanel li:focus-within {
  cursor: pointer;
}

ul.addPanel li:focus-within a {
  outline: none;
}

ul.addPanel li ul {
  visibility: hidden;
  opacity: 0;
  min-width: 181px;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 15px;
  right: -1px;
  display: none;
}

ul.addPanel li:hover > ul,
ul.addPanel li:focus-within > ul,
ul.addPanel li ul:hover,
ul.addPanel li ul:focus {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul.addPanel li ul li {
  clear: both;
  width: 100%;
}

ul.addPanel li ul {
  padding: 0;
}

.nav .addPanel button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 0;
  background-color: #a56122;
  color: white;
  cursor: pointer;
  text-align: left;
  font-size: 0.8rem;
  border-top: 1px solid #b87333;
}

.nav .addPanel button:hover {
  background-color: #99571a;
}

ul.addPanel li ul.shader-menu {
  right: 0;
  min-width: auto;
  height: 475px;
  display: grid;
  grid-template-columns: repeat(4, 191px);
  overflow: scroll;
}

ul.addPanel li ul.shader-menu li {
  min-height: 190px;
}
