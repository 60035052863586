@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans|IBM+Plex+Sans+Condensed&display=swap");

html,
body {
  font-family: "IBM Plex Sans", sans-serif;
  margin: 0;
  padding: 0;
  user-select: none;
}

#root {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.canvas {
  position: absolute;
  min-width: 5000px;
  min-height: 5000px;
  background-color: #ffffff;
  background-image: url("./grid.png");
  background-position: top left;
}

article {
  position: absolute;
  width: 350px;
  min-height: 120px;
  margin-bottom: 20px;
  background-color: gray;
  border-radius: 8px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  z-index: 2;
}

article.medium {
  width: 525px;
  min-height: 400px;
}

article.large {
  width: 700px;
  min-height: 600px;
}

article header {
  grid-area: header;
  min-height: auto;
  background-color: transparent;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  cursor: move;
  display: flex;
}
article header .close {
  margin-right: 10px;
  cursor: pointer;
}
article header span {
  flex: 1;
}

article header .indicators {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

article header .indicator {
  width: 18px;
  height: 18px;
  background-color: #444;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

article header .indicator svg {
  position: relative;
  color: rgba(255, 255, 255, 0.75);
}

article header .indicators .indicator {
  margin-left: 5px;
}

article header .indicators .indicator.yellow.on {
  color: yellow;
  background-color: currentcolor;
  box-shadow: 0 0 8px currentcolor;
}
article header .indicators .indicator.green.on {
  color: #40ff40;
  background-color: currentcolor;
  box-shadow: 0 0 8px currentcolor;
}
article header .indicators .indicator.flash {
  animation: flash;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

article .controls {
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

article .knobs {
  padding: 10px 20px;
  width: 100%;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
}
article .knobs.nopadding {
  padding: 0;
}
article .knobs.nopadding > canvas {
  border-radius: 0 0 10px 10px;
}
article .knobs.nopadding > div {
  padding: 10px;
  border-radius: 0 0 10px 10px;
}

article .knobs input[type="range"] {
  display: inline-block;
  width: 100%;
}
article .knobs input,
article .knobs select,
article .knobs button {
  display: inline-block;
  margin: 0 0 5px 0;
  border: 0;
  background-color: white;
  padding: 5px;
  border-radius: 3px;
}

article ul.knobs {
  padding: 5px 15px;
  list-style: none;
}
article ul.knobs li {
  width: 100%;
}
article ul.knobs li input[type="radio"] {
  width: auto;
}

article .io {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "inputs outputs";
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-size: 0.8rem;
}

article .node > div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid gray;
  cursor: pointer;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

article .node > div svg {
  position: relative;
}

article .input.node > div {
  left: -12px;
  pointer-events: all;
}

article .output.node > div {
  right: -10px;
  pointer-events: all;
}

article .node {
  font-family: "Courier New", Courier, monospace;
}

article .inputs {
  position: relative;
  list-style: none;
  padding: 5px 0;
  margin-top: 0;
  margin-bottom: 0;
}
article .inputs .input {
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-left: 20px;
  pointer-events: none;
}
article .inputs .input > div {
  background-color: #33d6ff;
}

article .outputs {
  position: relative;
  list-style: none;
  padding: 5px 0;
  margin-top: 0;
  margin-bottom: 0;
}
article .outputs .output {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 30px;
  margin-right: 20px;
}
article .outputs .output > div {
  background-color: #33ff4e;
}

article footer {
  flex: 1 1 20px;
  padding: 10px 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-size: 0.8rem;
}

@keyframes flash {
  0% {
    background-color: #444;
    box-shadow: none;
  }
  49% {
    background-color: #444;
    box-shadow: none;
  }
  50% {
    background-color: currentcolor;
    box-shadow: 0 0 8px currentcolor;
  }
  100% {
    background-color: currentcolor;
    box-shadow: 0 0 8px currentcolor;
  }
}

svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}

textarea {
  padding: 5px;
  width: 340px;
  min-height: 40px;
  font-family: "Courier New", Courier, monospace;
  border: 0;
}

.active {
  cursor: grabbing !important;
}

/*
* Typed nodes - colors
*/

article .node > div svg {
  color: rgba(255, 255, 255, 0.75);
}

article .inputs .input.any > div,
article .outputs .output.any > div {
  background-color: #00f11d;
}

article .inputs .input.float > div,
article .outputs .output.float > div {
  background-color: #ff7f00;
}

article .inputs .input.string > div,
article .outputs .output.string > div {
  background-color: #ff0900;
}

article .inputs .input.vec3 > div,
article .outputs .output.vec3 > div {
  background-color: #ffef00;
}

article .inputs .input.array > div,
article .outputs .output.array > div {
  background-color: #a800ff;
}

article .inputs .input.event > div,
article .outputs .output.event > div {
  background-color: #0079ff;
}

div.uniforms {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

div.uniforms > input[disabled] {
  background-color: #aaa;
}

.template-output {
  white-space: pre;
}

.template-output > span {
  color: #f88;
}

.keyboard {
  height: 100px;
  width: 100%;
  margin-top: 5px;
  background-color: rgba(255, 255, 255, 0.1);
}

div.uniforms > input[disabled].pressed {
  background-color: rgb(0, 255, 0);
}

.placeholder {
  min-height: 120px;
  display: grid;
  align-items: center;
  justify-content: center;
}

.placeholder > svg {
  position: relative;
}
