.footer {
  position: absolute;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background-color: #b87333;
  color: #fff;
  align-items: center;
  z-index: 9;
}

.footer h1 {
  flex: 1;
  padding: 0 20px;
  margin: 0;
  font-size: 1rem;
}
